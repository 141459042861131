.contact-locations {
	display: grid;
	grid-gap: 30px;
	.contact-location {
		h2 {
			font-size: rem-calc(20);
			margin-bottom: 20px;
		}
		.inline-icon {
			margin-bottom: 10px;
		}
	}
}

@include breakpoint(400px) {
	.contact-locations {
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	}
	.contact-location {
		display: flex;
		align-items: flex-start;
		.map-image {
			width: 100px;
			flex-shrink: 0;
		}
		.text {
			padding-left: 20px;
		}
	}
}

@include breakpoint(large) {
	.contact-location {
		.map-image {
			width: auto;
		}
	}
}