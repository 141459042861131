/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/
@media  only screen and (max-width: 53.124999em) {
	body.nav-overlay {
		width: 100vw;
		height: 100vh;
		overflow: hidden;
		position: fixed;
	}
}

$page-header-padding: 30px;

.page-header {
	background-color: $brand;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: $page-header-padding 0;
    .logo {
    	max-width: 130px;
    	img {
    		margin-bottom: 0;
    	}
    }
    > .inner {
    	display: flex;
    	align-items: center;
    	justify-content: space-between;
    }
    .main-nav {
    	display: none;
    }
    .nav-toggle {
    	font-family: $alt-font;
    	font-weight: $alt-bold;
    	text-transform: uppercase;
    	display: flex;
    	align-items: center;
    	a {
    		color: #666;
    		padding-right: 5px;
    		text-decoration: none;
    		&:hover, &:focus {
    			color: $brand;
    		}
    	}
    	&:hover a, &:focus a {
    		color: $brand;
    	}
    	&.active a {
    		display: none;
    	}
    }
}

.offscreen-nav {
	width: 100%;
	padding: $page-header-padding 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 30;
	display: none;
	.logo {
		max-width: 130px;
		img {
			margin-bottom: 0;
		}
	}
	.offscreen-nav-header .inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

@include breakpoint(850px) {
	.page-header {
		padding: 24px 0;
		.offscreen-nav,
		.nav-toggle {
			display: none !important;
		}
	    .main-nav {
	    	display: block;
	    }
	    > .inner {
	    	display: flex;
	    	align-items: flex-end;
	    	justify-content: space-between;
	    }
	}
}

@include breakpoint(900px) {
	.page-header {
		.logo {
			max-width: 100%;
		}
	}
}

/*------------------------------------*\
    @group Navigation
\*------------------------------------*/

.offscreen-nav {
	background-color: $brand;
	width: 100%;
	height: 100vh;
	padding: $page-header-padding 0;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: none;
	z-index: 500;
	.inner {
		position: relative;
	}
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.logo {
		img {
			margin: 0;
		}
	}
	.nav-toggle {
		margin-left: 20px;
	}

	a {
		color: $brand-alt;
		text-decoration: none;
		&:focus, &:hover {
			color: $brand-accent;
			text-decoration: none;
		}
	}

	nav {
		width: 100%;
		font-size: rem-calc(20);
		font-weight: $main-bold;
		color: $white;
		padding-top: 20px;
		padding-bottom: 30px;
		border-bottom: solid 2px $brand-alt-accent;
		margin: 0 0 30px;
		a {
			color: $white;
			line-height: 1;
			&:focus, &:hover {
				color: $brand-accent;
			}
		}
		ul {
			margin-bottom: 0;
		}
		li {
			margin-bottom: 10px;
		}
		.has-dropdown  {
			cursor: pointer;
			> span {
				display: inline-flex;
				align-items: center;
				&::after {
					@include css-triangle(6px, $brand-accent, down);
					margin-left: 10px;
					display: inline-block;
				}
			}
			&.open {
				color: $brand-accent;
				.dropdown {
					display: block;
				}
			}
		}
		.dropdown {
			padding-top: 10px;
			display: none;
			a {
				color: $brand-alt;
				&:focus, &:hover {
					color: $brand-accent;
				}
			}
			li {
				padding-left: 14px;
			}
		}
	}
	.sub-nav {
		margin-bottom: 20px;
		li {
			margin-bottom: 10px;
		}
	}
}

.main-nav {
	font-size: rem-calc(18);
	font-weight: $main-bold;
	color: $white;
	line-height: 1.33;
	li {
		margin: 0 0 0 30px;
		display: inline-block;
	}
	a {
		color: $white;
		text-decoration: none;
		&:hover, &:focus {
			color: $brand-accent;
			text-decoration: none;
		}
	}
	.has-dropdown  {
		cursor: pointer;
		position: relative;
		padding-bottom: 24px;
		margin-bottom: -24px;
		&:hover, &:focus {
			color: $brand-accent;
			.dropdown {
				z-index: 30;
				opacity: 1;
				visibility: visible;
			}
		}
		> span {
			display: inline-flex;
			align-items: center;
			&::after {
				@include css-triangle(6px, $brand-accent, down);
				margin-left: 10px;
				display: inline-block;
			}
		}
	}
	.dropdown {
		min-width: 235px;
		background: $brand;
		font-size: rem-calc(16);
		padding: 20px;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
		border-radius: 0 0 2px 2px;
		top: 100%;
		left: -20px;
		position: absolute;
		visibility: hidden;
		opacity: 0;
		z-index: -1;
		transition: opacity .6s, visibility .6s;
		a {
			color: $brand-alt;
			&:hover, &:focus {
				color: $brand-accent;
			}
		}
		li {
			margin: 0 0 10px;
			padding: 0;
		}
	}
}
/* @end Navigation */