/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    background: $brand;
    font-size: rem-calc(14);
    color: $brand-alt;
    padding: 30px 0 20px;
    .logo {
        margin-bottom: 30px;
        img {
            margin-bottom: 0;
        }
    }
    li {
        &::before {
            display: none;
        }
    }
    h2 {
        font-size: rem-calc(18);
        color: $white;
        line-height: 1.17;
        padding-bottom: 4px;
        border-bottom: solid 2px $brand-alt;
        margin-bottom: 9px;
    }
    h3 {
        font-size: rem-calc(14);
        color: $white;
        line-height: 1.5;
        margin-bottom: 4px;
    }
    p {
        padding-left: 10px;
        margin-bottom: 4px;
    }
    a {
        color: $light-red;
        &:hover, &:focus {
            color: $brand-accent;
        }
    }
    .footer-contact-option {
        margin-bottom: 32px;
    }
    .post-footer {
        font-size: rem-calc(12);
        a {
            color: $brand-alt;
            &:hover, &:focus {
                color: $brand-accent;
            }
        }
        ul, li, p {
            padding: 0;
        }
        .sub-navs {
            margin-bottom: 30px;
        }
    }
}

@include breakpoint(420px) {
    .page-footer .post-footer {
        .sub-navs {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            ul {
                margin: 0;
            }
        }
        .sub-nav {
            display: flex;
            li {
                margin-right: 20px;
                margin-bottom: 0;
            }
        }
    }
}

@include breakpoint(500px) {
    .page-footer {
        .footer-contact-options {
            display: flex;
        }
        .footer-contact-option {
            flex-basis: 50%;
        }
    }
}

@include breakpoint(900px) {
    .page-footer {
        .post-footer .inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .copyright {
                order: 0;
            }
            .sub-navs {
                order: 1;
            }
        }
        .main-footer .inner {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .footer-contact {
                flex-basis: 670px;
            }
        }
    }
}