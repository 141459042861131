/*----------------------------------------------------------*\
    Slider

    All styles for sliders uses throughout the site are in
    this file.

\*----------------------------------------------------------*/

.slider-feature-wrapper {
	margin-bottom: 30px;
	h3 {
		font-size: rem-calc(24);
	}
}

.slider-feature-nav {
	margin-bottom: 50px;
	h3 {
		margin-bottom: 30px;
	}
}

.slider-feature-nav-items {
	border-left: solid 4px #dee8f2;
}

.slider-feature-nav-item {
	font-size: rem-calc(20);
	color: $main-color;
	padding-left: 30px;
	border-left: solid 4px transparent;
	margin-left: -4px;
	margin-bottom: 18px;
	cursor: pointer;
	&:last-child {
		margin-bottom: 0;
	}
	p {
		margin: 0;
	}
	&:hover, &:focus {
		color: $brand-accent;
	}
	&.active {
		border-color: $brand-accent;
		color: $brand-accent;
	}
}

.slider-feature-container {
	padding-left: 30px;
	border-left: solid 4px $brand-alt;
	h3 {
		margin-bottom: 16px;
	}
	ul, ol {
		margin: 30px 0;
	}
}


@include breakpoint(large) {
	.slider-feature-inner {
		overflow: hidden;
		.slider-feature-nav {
			float: left;
			width: 38%;
		}
		.slider-feature-container-wrapper {
			float: left;
			width: 62%;
		}
		.slider-feature-nav-item.active {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-template-rows: 20px 0;
			grid-column-gap: 20px;
			&::after {
				content: '';
				display: block;
				border-bottom: solid 4px $brand-alt;
			}
		}
	}
}

/* @end */