.resource-grid-intro {
	max-width: 570px;
	margin-bottom: 36px;
}

.resource-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
	grid-gap: 30px 50px;
	h3 {
		font-size: rem-calc(20);
		color: $brand-alt-accent;
		margin-bottom: 6px;
	}
	a {
		line-height: 1.4;
		display: inline-block;
	}
	.icon-list {
		margin-top: 0;
	}
}