.feature-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
	grid-gap: 50px 40px;
}

.feature-grid-item {
	img {
		margin-bottom: 10px;
	}
	h3 {
		font-size: rem-calc(20);
		margin-bottom: 5px;
	}
	p {
		margin-bottom: 15px;
	}
}