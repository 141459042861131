.feature-details-page {
	img {
		width: 60px;
		margin-bottom: 20px;
	}
}

@include breakpoint(500px) {

	.feature-details-page {
		display: grid;
		grid-template-columns: 190px auto;
		grid-column-gap: 30px;
		img {
			width: 100%;
		}
	}

}