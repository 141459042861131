/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

#main-content {
	overflow-x: hidden;
}

.page-content {
    background: $white;
    padding: 50px 0 30px;
    @include breakpoint(large) {
    	padding: 100px 0 80px;
    }
}
