/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/

// * {
//   border: 1px solid red;
// }

// html, body {
// 	min-height: 100vh;
// 	height: 100vh;
// }

body {
	background: $white;
	font-size: rem-calc(16);
	font-family: $main-font;
	font-weight: $main-normal;
	color: $main-color;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-areas: "page-header" "main-content" "page-footer"
}

.page-header {
	grid-area: "page-header";
}

#main-content {
	grid-area: "main-content";
}

.page-footer {
	grid-area: "page-footer";
}

p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0 0 20px;
	&.large {
		@include font-size(large);
		margin-bottom: 18px;
	}
	&.small {
		@include font-size(small);
		margin-bottom: 8px;
	}
	&.normal {
		@include font-size(default);
		margin-bottom: 24px;
	}
}
a:not(.button) {
	color: $link-base;
	transition: all .3s;
	text-decoration: none;
	text-decoration-color: transparent;
	transition: text-decoration-color .3s, color .3s;
	&:hover,
	&:focus {
		text-decoration: underline;
		text-decoration-skip-ink: auto;
		text-decoration-color: $brand-accent;
	}
}

a.plus-link {
	background: url('/images/icons/plus.svg') no-repeat left 2px;
	font-size: rem-calc(14);
	font-weight: $main-bold;
	padding-left: 14px;
}
hr {
	margin: 20px 0;
}
.inner {
	@include xy-grid-container;
}

.inner.narrow {
	max-width: 970px;
}

/* @end */