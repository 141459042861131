/*----------------------------------------------------------*\
   Spacing Sections

   Sections can be used to increase whitespace between
   content sections as needed. The recommended method is
   wrapping the content in a div and applying the class
   with the spacing you want. The -full suffix will apply
   padding to the top and bottom which is well-suited for
   content areas that span the full width of the screen
   and use a background color.

\*----------------------------------------------------------*/

.content-section-full {
	padding: $full-section-spacing 0 $full-section-spacing - 20px;
}
.intro-section {
	padding-bottom: $intro-section-spacing-small;
}
.content-section {
	padding-bottom: $content-section-spacing-small;
}
.content-sub-section {
	padding-bottom: $content-sub-section-spacing-small;
}

@media screen and #{breakpoint(medium)} {
	.content-section-full {
		padding: $full-section-spacing 0;
	}
	.intro-section {
		padding-bottom: $intro-section-spacing;
	}
	.content-section {
		padding-bottom: $content-section-spacing;
	}
	.content-sub-section {
		padding-bottom: $content-sub-section-spacing;
	}
}


.section-dark {
	color: $white;
	h1, h2, h3 {
		color: $brand-alt;
	}
	label {
		color: $brand-alt;
	}
}