.social-list {
	svg {

	}
}

.social-list.large-icons {
	svg {
		height: 30px;
		width: 30px;
	}
}