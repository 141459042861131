/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/

//Font families
$sans-serif-font: "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif;;
$serif-font: sans-serif;
$icon-font: 'StarterTemplate';
$heading-font: $sans-serif-font;
$main-font: $sans-serif-font;
$alt-font: $heading-font;

//Font weights
$main-normal: 400;
$main-bold: 700;
$alt-normal: 400;
$alt-bold: 700;
$heading-normal: $alt-normal;
$heading-bold: $alt-bold;

//Font sizes
$font-sizes: (
  small: (.75rem, 1.33),
  default: (1rem, 1.5),
  large: (1.25rem, 1.5),
  small-desktop: (rem-calc(20), 1.5),
  default-desktop: (rem-calc(22), 1.67),
  large-desktop: (rem-calc(29), 1.31)
);


//Styleguide colors
$brand:				 #3e444b;
$brand-accent:		 #e74a42;
$brand-alt:			 #b7c1cc;
$brand-alt-accent:	 #747f8b;
$light-red:	         #FF9D97;
$dark-red:	         #B73C35;
$white:              #FFFFFF;
$black:              #000000;
$gray:               gray;

$colors: (
	'brand':			 $brand,
	'brand-accent':		 $brand-accent,
	'brand-alt':		 $brand-alt,
	'brand-alt-accent':	 $brand-alt-accent,
	'light-red':         $light-red,
	'dark-red':          $dark-red,
	'white':             $white,
	'black':             $black
);

//Gradients, for use on backgrounds only
$gradients: (
	'white': linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%)
);

//Transparent background colors
$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.7);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $brand;

//Link colors
$link-base: $brand-accent;
$link-active: $brand-accent;

//Social media brand colors
$social: (
	'twitter': 		 #00aced,
	'facebook': 	 #3b5998,
	'googleplus': 	 #dd4b39,
	'pinterest': 	 #cb2027,
	'linkedin': 	 #007bb6,
	'youtube': 		 #bb0000,
	'vimeo': 		 #aad450,
	'instagram': 	 #517fa4,
	'quora': 		 #a82400,
	'foursquare': 	 #0072b1,
	'stumbleupon': 	 #EB4823,
	'soundcloud': 	 #ff3a00
);

//Shadows
$text-shadow: 1px 0 1px rgba(0,0,0,0.65);
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow: 0 1px 1px rgba(0,0,0,1);

//Rounded corners
$border-round: 1000px;
$border-radius: 2px;

//Section spacing
$full-section-spacing-small: 		30px;
$content-section-spacing-small: 	30px;
$content-sub-section-spacing-small: 15px;
$intro-section-spacing-small: 		30px;
$full-section-spacing: 				50px;
$content-section-spacing: 			50px;
$content-sub-section-spacing: 		30px;
$intro-section-spacing: 			30px;

//Hero backgrounds
$hero-bg: (
	'home-orange': test
);

$hero-bg-color: (
	'home-orange': #ff9900
);