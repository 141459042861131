.section-bg {
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
}

.bg-devices {
	background-image: url('/images/bg/sections/bg_device_collage.png');
	@include breakpoint(medium) {
		background-image: url('/images/bg/sections/bg_device_collage_med.png');
	}
	@include breakpoint(large) {
		background-image: url('/images/bg/sections/bg_device_collage_lrg.png');
	}
}