/*----------------------------------------------------------*\
	Callouts

	Some of the callout styles come from the Foundation
	settings file. The styles listed here apply to the
	default callouts. Additional callout options can be
	seen on the styleguide.

\*----------------------------------------------------------*/

.callout {
	background: $brand;
	max-width: 970px;
	padding: 30px 30px 10px;
	margin: 0 auto 20px;
	h2 {
		font-size: rem-calc(30);
		color: $brand-alt;
		margin-bottom: 6px;
	}
	p {
		font-size: rem-calc(18);
		color: $white;
		line-height: 1.5;
		margin: 0 0 20px;
	}
	.button {
		margin: 0 0 20px;
	}
}

@include breakpoint(700px) {
	.callout {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.callout-text {
			padding-right: 30px;
		}
		.callout-action {
			flex-shrink: 0;
		}
	}
}

.quote-callout {
	background-image: linear-gradient(to top, #b7c1cc 0%, #dee8f2 100%);
	border-radius: 5px;
	padding: 30px;
	blockquote {
		font-style: italic;
		margin: 0;
		p {
			position: relative;
			&::before {
				content: '';
				width: 40px;
				height: 36px;
				background: url('/images/icons/quotes.svg') no-repeat left top;
				margin-right: 20px;
				display: inline-block;
			}
		}
	}
	cite {
		font-weight: $main-bold;
		text-align: right;
		display: block;
	}
}

@include breakpoint(medium) {
	.quote-callout blockquote {
		p {
			padding-left: 93px;
			&::before {
				width: 73px;
				height: 64px;
				background-size: cover;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}
}