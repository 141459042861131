/*----------------------------------------------------------*\
   Headings

   The headings follow a modular scale and vertical rhythm
   that changes between small and larger views. To keep
   your headings semantic, you can apply the heading classes
   to different headings for stylistic purposes while
   still using the appropriate heading hierarchy in your
   HTML.

\*----------------------------------------------------------*/

h1, .h1 {
   font-size: rem-calc(40);
   font-weight: $heading-bold;
   color: $main-color;
   line-height: 1;
   margin: 0 0 20px;
}

h2, .h2 {
   font-size: rem-calc(34);
   font-weight: $heading-bold;
   color: $main-color;
   line-height: 1.18;
   margin: 0 0 10px;
}
h3, .h3 {
   font-size: rem-calc(27);
   font-weight: $heading-bold;
   color: $main-color;
   line-height: 1.2;
   margin: 0 0 4px;
}
h4, .h4 {
   font-size: rem-calc(22);
   font-weight: $heading-bold;
   color: $main-color;
   line-height: 1.3636;
   margin: 0 0 .5em;
}

.section-heading {
   text-align: center;
   margin-bottom: 25px;
}

@include breakpoint(medium) {
   .section-heading {
      margin-bottom: 50px;
   }
	// h1, .h1 {
	//    font-size: rem-calc(61);
	//    line-height: 1.082;
	// }

	// h2, .h2 {
	//    font-size: rem-calc(39);
	//    line-height: 1.077;
	// }

	// h3, .h3 {
	//    font-size: rem-calc(32);
	//    line-height: 1.125;
	// }

	// h4, .h4 {
	//    font-size: rem-calc(25);
	//    line-height: 1.2;
	// }
}