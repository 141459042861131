/*------------------------------------*\
    @group Hero
\*------------------------------------*/

.hero {
	padding-top: 50px;
	.subhead {
		font-size: rem-calc(20);
		font-weight: $main-bold;
		color: $brand-alt-accent;
		line-height: 1.1;
		margin-bottom: 20px;
	}
	.hero-content {
		margin-bottom: 50px;
	}
	.hero-media {
		margin-bottom: -10px;
		img {
			margin-bottom: 0;
		}
	}
	.hero-logo img {
		max-width: 200px;
	}
}

@include breakpoint(medium) {
	.hero {
		.inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.hero-media {
			padding-left: 30px;
			align-self: flex-end;
		}
	}
}

@include breakpoint(1000px) {
	.hero .hero-logo img {
		max-width: 100%;
	}
}

//Loops over $hero-bg in _variables.scss and creates files with mixin
@each $name, $filename in $hero-bg {
  .#{$name} {
	@include hero-image($filename: $filename);
	background-color: map-get($hero-bg-color, $name);
  }
}

/* @end Hero */