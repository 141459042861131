.icon-grid {
	font-weight: $main-bold;
	color: $brand-alt;
	margin: 30px 0;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
	grid-gap: 30px;
	p {
		line-height: 1.3;
	}
	img {
		display: block;
	}
}