.logo-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px;
	grid-auto-rows: 120px;
	align-content: center;
}

.logo-grid-item {
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	border: solid 1px #C9CFD6;
	img {
		margin: 0;
	}
}

@include breakpoint(510px) {
	.logo-grid {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}
}